<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <UserNavbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div
      class="body-container"
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '30px' }
          : { 'padding-bottom': '70px' },
      ]"
    >
      <v-container class="px-10 px-md-auto">
        <div>
          <div class="mx-0 text-h2 text-md-h1 font-weight-bold">
            Servicios Zaturna
          </div>
          <div
            class="flex-row text-subtitle-1 text-sm-h4 font-weight-regular mb-4 ml-md-1"
            :class="loadingFirstServices && 'mb-16 mb-sm-12 pb-2 pb-sm-0'"
          >
            <div v-if="!loadingFirstServices">
              <span class="d-flex d-sm-inline-flex">
                <!-- Format numbers with commas -->
                {{
                  servicesQuantity !== 1
                    ? servicesQuantity +
                      " servicios " +
                      (filtersUsed ? "encontrados" : "publicados")
                    : servicesQuantity +
                      " servicio " +
                      (filtersUsed ? "encontrado" : "publicado")
                }}
              </span>
              <span class="d-flex d-sm-inline-flex mx-sm-8">
                <v-icon class="mr-2 secondary--text" small>fa-map-pin</v-icon>
                <!-- Format numbers with commas -->
                {{
                  citiesQuantity != 1
                    ? citiesQuantity + " ciudades"
                    : citiesQuantity + " ciudad"
                }}
              </span>
            </div>
          </div>
        </div>
        <v-divider class="mx-n2 secondary" />
        <CardsFilters
          :filters="filters"
          :filters-used="filtersUsed"
          @removeFilters="removeFilters"
        />
        <div
          v-if="
            !loadingFirstServices &&
            !loadingMoreServices &&
            filtersUsed &&
            servicesQuantity === 0
          "
          class="my-16 text-center descent--text text--darken-2 text-h4 font-weight-regular"
        >
          No se han encontrado servicios
        </div>
      </v-container>
      <InfiniteListCards
        v-if="!filtersUsed || servicesQuantity !== 0"
        :cards="services"
        :no-more-cards="noMoreServices"
        :loading-first-cards="loadingFirstServices"
        :loading-more-cards="loadingMoreServices"
        type="Service"
        @handleMoreCards="fetchMoreServices"
      />
      <v-container
        v-if="loadingFirstServices || loadingMoreServices"
        class="d-flex flex-column align-center my-10 my-sm-12"
      >
        <v-progress-circular indeterminate color="secondary" :size="100" />
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/User/Shared/Navbar.vue";
import UserNavbar from "@/components/User/Shared/UserNavbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
import CardsFilters from "@/components/Shared/CardsFilters.vue";
import InfiniteListCards from "@/components/User/Shared/InfiniteListCards.vue";
import Footer from "@/components/Shared/Footer.vue";
import {
  GET_SERVICES_WITHOUT_CURSOR,
  GET_SERVICES_WITH_CURSOR,
  GET_SERVICES_DISTINCT_CATEGORIES,
  GET_SERVICES_DISTINCT_LOCATIONS,
} from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
import { getDistinctCitiesByState } from "@/Utils/locations.js";

export default {
  name: "Services",
  components: {
    Navbar,
    UserNavbar,
    SearchBar,
    CardsFilters,
    InfiniteListCards,
    Footer,
  },
  data: () => ({
    filters: [
      {
        type: "search",
        name: "Nombre",
        model: "",
      },
      {
        type: "options",
        name: "Tipo de servicio",
        model: "",
        options: [],
        noDataText: "Tipo de servicio no encontrado",
        autofocus: false,
      },
      {
        type: "location",
        state: {
          name: "Estado",
          model: "",
          options: [],
        },
        city: {
          name: "Ciudad",
          model: "",
          options: getDistinctCitiesByState,
          distinctOptions: [],
        },
      },
      // {
      //   type: "rating",
      //   name: "Calificación",
      //   emptyIcon: "far fa-star text--lighten-4",
      //   fullIcon: "fa-star secondary--text",
      //   model: null,
      //   menuModel: false,
      //   menu: true,
      // },
      {
        type: "rating",
        name: "Rango de precios",
        emptyIcon: "fa-dollar-sign",
        fullIcon: "fa-dollar-sign",
        model: null,
      },
    ],
    services: [],
    servicesQuantity: 0,
    citiesQuantity: 0,
    noMoreServices: false,
    loadingFirstServices: true,
    loadingMoreServices: false,
  }),
  computed: {
    servicesPerPageQuantity() {
      return this.$vuetify.breakpoint.xs
        ? 6
        : this.$vuetify.breakpoint.sm
        ? 10
        : 16;
    },
    filtersValues() {
      return [
        this.filters[0].model,
        this.filters[1].model,
        this.filters[2].state.model,
        this.filters[2].city.model,
        this.filters[3].model,
      ];
    },
    filtersUsed() {
      if (
        this.filters[0].model ||
        this.filters[1].model ||
        this.filters[2].state.model ||
        this.filters[2].city.model ||
        this.filters[3].model
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    "$route.query.nombre": {
      async handler(val) {
        if (!val) return;
        this.filters[0].model = val;
      },
      immediate: true,
    },
    "$route.query.estado": {
      async handler(val) {
        if (!val) return;
        this.filters[2].state.model = val;
      },
      immediate: true,
    },
    "$route.query.ciudad": {
      async handler(val) {
        if (!val) return;
        this.filters[2].city.model = val;
      },
      immediate: true,
    },
    filtersValues: function () {
      this.fetchFirstServices();
    },
  },
  created() {
    this.fetchFirstServices();
  },
  methods: {
    loadFilters(props) {
      if (this.filters[0].model !== "") {
        props = { ...props, name: this.filters[0].model };
      }
      if (this.filters[1].model !== "") {
        props = { ...props, category: this.filters[1].model };
      }
      if (this.filters[2].state.model !== "") {
        props = { ...props, state: this.filters[2].state.model };
        if (this.filters[2].city.model !== "") {
          props = { ...props, city: this.filters[2].city.model };
        }
      }
      if (this.filters[3].model) {
        props = { ...props, priceRange: this.filters[3].model };
      }
      return props;
    },
    async fetchDistinctCategories(props) {
      const { data, errors } = await useQuery(
        GET_SERVICES_DISTINCT_CATEGORIES,
        props
      );
      if (data) {
        this.filters[1].options = data.distinctServicesCategories.categories;
      } else if (errors) {
        console.log(errors);
      }
    },
    async fetchDistinctLocations(props) {
      const { data, errors } = await useQuery(
        GET_SERVICES_DISTINCT_LOCATIONS,
        props
      );
      if (data) {
        this.filters[2].state.options = data.distinctServicesLocations.states;
        this.filters[2].city.distinctOptions =
          data.distinctServicesLocations.cities;
      } else if (errors) {
        console.log(errors);
      }
    },
    async fetchFirstServices() {
      this.loadingFirstServices = true;
      this.noMoreServices = false;
      this.services = [];
      let props = { perPage: this.servicesPerPageQuantity };
      props = this.loadFilters(props);

      this.fetchDistinctCategories(props);
      this.fetchDistinctLocations(props);

      const { data, errors } = await useQuery(
        GET_SERVICES_WITHOUT_CURSOR,
        props
      );
      if (data) {
        this.services = this.formatServices(data.services.services);
        this.cursor = data.services.cursor;
        this.servicesQuantity = data.countServices.servicesCount;
        this.citiesQuantity = data.countServices.servicesCitiesCount;
        if (this.servicesQuantity <= this.servicesPerPageQuantity) {
          this.noMoreServices = true;
        }
      } else if (errors) {
        console.log(errors);
      }
      this.loadingFirstServices = false;
    },
    async fetchMoreServices() {
      if (
        !this.loadingFirstServices &&
        !this.loadingMoreServices &&
        !this.noMoreServices
      ) {
        this.loadingMoreServices = true;
        let props = {
          perPage: this.servicesPerPageQuantity,
          cursor: this.cursor,
        };
        props = this.loadFilters(props);

        const { data, errors } = await useQuery(
          GET_SERVICES_WITH_CURSOR,
          props
        );
        if (data) {
          const newServicesToAppend = this.formatServices(
            data.services.services
          );
          this.services = [...this.services, ...newServicesToAppend];
          this.cursor = data.services.cursor;
          this.servicesQuantity = data.countServices.servicesCount;
          this.citiesQuantity = data.countServices.servicesCitiesCount;
          if (this.servicesQuantity === this.services.length) {
            this.noMoreServices = true;
          }
        } else if (errors) {
          console.log(errors);
        }
        this.loadingMoreServices = false;
      }
    },
    formatServices(services) {
      const data = services.map((service) => {
        const info = {
          ...service.service,
          providerName: service.provider.name,
          rating:
            service.service.serviceComment.reduce(
              (total, currentValue) => total + currentValue.score,
              0
            ) / service.service.serviceComment.length,
          reviews: service.service.serviceComment.length,
        };
        return info;
      });
      return data;
    },
    removeFilters() {
      this.$router.push({ query: null }).catch(() => {
        this.filters.forEach((filter) => {
          if (filter.type === "location") {
            filter.state.model = "";
            filter.city.model = "";
          } else if (filter.type === "rating") {
            filter.model = 0;
          } else {
            filter.model = "";
          }
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}
.slide-y-transition-leave-to {
  transform: none;
}
</style>
